<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Ketentuan Komisi" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <vs-table :data="termins" stripe class="fix-table-input-background">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Urut</vs-th>
            <vs-th class="whitespace-no-wrap">Syarat</vs-th>
            <vs-th class="whitespace-no-wrap">Komisi Cair</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td class="w-4 font-semibold">{{ item.no_urut }}</vs-td>
              <vs-td class="w-1/2">
                <div class="flex">
                  <vs-input class="w-full" :value="item.syarat_cair" readonly/>
                  <vx-input-group class="ml-1 w-full" v-if="item.syarat_cair === 'PERSEN TERCAPAI'">
                    <vs-input class="w-full" :value="item.syarat_cair_persen" readonly/>
                    <template slot="append">
                      <div class="append-text bg-primary"><span>%</span></div>
                    </template>
                  </vx-input-group>
                </div>
              </vs-td>
              <vs-td class="w-1/2">
                <div class="flex">
                  <vs-input class="w-full" :value="item.komisi_cair" readonly/>
                  <vx-input-group class="ml-1 w-full" v-if="item.komisi_cair === 'PERSEN TOTAL KOMISI'">
                    <vs-input class="w-full" :value="item.komisi_cair_persen" readonly/>
                    <template slot="append">
                      <div class="append-text bg-primary"><span>%</span></div>
                    </template>
                  </vx-input-group>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

    </vs-popup>
  </div>
</template>

<script>
export default {
  name: 'TerminKomisi',
  props: {
    isActive: { required: true, type: Boolean },
    termins: { required: true, type: Array }
  },
  methods: {
    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
