<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-primary con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Silahkan tambahkan hadiah jika ada.
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <div class="flex flex-wrap justify-between items-center mb-1">
          <div class="mb-4 md:mb-0 mr-4">
            <div class="flex">
              <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
              <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="hadiah.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col sm:w-1/2 w-full px-0">
        <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
      </div>
    </div>
    <vs-table :data="rowsHadiah" stripe class="fix-table-input-background">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">Hadiah</vs-th>
        <vs-th class="whitespace-no-wrap">Tgl *</vs-th>
        <vs-th class="whitespace-no-wrap">No Bukti *</vs-th>
        <vs-th class="whitespace-no-wrap">Qty *</vs-th>
        <vs-th class="whitespace-no-wrap">Qty Diterima *</vs-th>
        <vs-th class="whitespace-no-wrap">Nilai</vs-th>
        <vs-th class="whitespace-no-wrap">Gambar</vs-th>
        <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td>{{ index + 1 }}</vs-td>
          <vs-td>
            <div class="w-48">
              <vx-input-group>
                <vs-input :value="item.nama_hadiah" readonly @click="chooseHadiah(item.uuid)"/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="chooseHadiah(item.uuid)"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </vs-td>
          <vs-td><flat-pickr v-model="item.tgl"></flat-pickr></vs-td>
          <vs-td><vs-input v-model="item.no_bukti"/></vs-td>
          <vs-td><vs-input type="number" v-model="item.qty"/></vs-td>
          <vs-td><vs-input type="number" v-model="item.qty_diterima"/></vs-td>
          <vs-td><vs-input readonly :value="(item.qty * item.harga_hadiah) | idr"/></vs-td>
          <vs-td><vs-input v-model="item.gambarTemp" type="file" accept=".jpeg,.jpg,.png,.pdf" @change="item.gambar = $event.target.files[0]"/></vs-td>
          <vs-td><vs-input v-model="item.keterangan"/></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-6">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
      </div>
    </div>

    <!--modal hadiah-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data Hadiah"
              :active="modalHadiah.active"
              v-on:update:active="modalHadiah.active = $event">
      <Hadiah :selectable="true" @selected="onSelectedModalHadiah"/>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import flatPickr from 'vue-flatpickr-component'
import Hadiah from '@/views/pages/master/hadiah/Hadiah'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'

export default {
  name: 'TabHadiah',
  components: {
    Hadiah,
    flatPickr,
    ValidationErrors
  },
  watch: {
    hadiah: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      search: null,
      modalHadiah: {
        rowUuid: null, // uuid from this.hadiah[n].uuid
        active: false
      },
      hadiah: []
    }
  },
  computed: {
    errors () {
      return this.$store.state.marketing.kontrakAdd.errors.tabHadiah.messages
    },
    rowsHadiah () {
      const search = this.search
      const items = this.hadiah
      if (search != null) {
        return _.filter(items, (item) => {
          return item.nama.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  methods: {
    chooseHadiah (rowUuid) {
      this.modalHadiah.rowUuid = rowUuid
      this.modalHadiah.active = true
    },

    onSelectedModalHadiah (data) {
      const uuid = this.modalHadiah.rowUuid
      const index = _.findIndex(this.hadiah, item => item.uuid === uuid)
      this.hadiah[index].id_hadiah = data.id
      this.hadiah[index].nama_hadiah = data.nama
      this.hadiah[index].harga_hadiah = data.harga
      this.modalHadiah.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        id_hadiah: null,
        nama_hadiah: null,
        harga_hadiah: null,
        tgl: null,
        no_bukti: null,
        qty: null,
        nilai: null,
        qty_diterima: null,
        gambar: null,
        keterangan: null
      }
      this.search = null
      this.hadiah.unshift(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.hadiah, item => item.uuid === uuid)
      this.hadiah.splice(index, 1)
    },

    clearRows () {
      this.hadiah = []
    },

    commitDataToStore: _.debounce(function () {
      const payload = this.buildPayload()
      this.$store.commit('marketing/kontrakAdd/SET_TAB_HADIAH', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 4)
    },

    next () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 6)
    },

    buildPayload () {
      return this.hadiah.map(it => {
        return {
          id_hadiah: it.id_hadiah,
          tgl: it.tgl,
          no_bukti: it.no_bukti
        }
      })
    }
  }
}
</script>
