<template>
  <div>
    <div class="vx-row mb-1">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-4">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-primary con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Tambahkan daftar penghuni yang akan menempati unit.
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <div class="flex flex-wrap justify-between items-center mb-1">
          <div class="mb-4 md:mb-0 mr-4">
            <div class="flex">
              <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
              <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="penghuni.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col sm:w-1/2 w-full px-0">
        <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
      </div>
    </div>
    <vs-table :data="rowsPenghuni" stripe class="fix-table-input-background">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">Nama</vs-th>
        <vs-th class="whitespace-no-wrap">Telp</vs-th>
        <vs-th class="whitespace-no-wrap">Email</vs-th>
        <vs-th class="whitespace-no-wrap">Status Keluarga</vs-th>
        <vs-th class="whitespace-no-wrap">Alamat</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td>
            <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
          </vs-td>
          <vs-td><vs-input v-model="item.nama"/></vs-td>
          <vs-td><vs-input v-model="item.telp"/></vs-td>
          <vs-td><vs-input v-model="item.email"/></vs-td>
          <vs-td><vs-input v-model="item.status_keluarga"/></vs-td>
          <vs-td><vs-input v-model="item.alamat"/></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-6">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'

export default {
  name: 'TabPenghuni',
  props: {},
  components: {
    ValidationErrors
  },
  watch: {
    penghuni: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      search: null,
      penghuni: []
    }
  },
  computed: {
    errors () {
      return this.$store.state.marketing.kontrakAdd.errors.tabPenghuni.messages
    },
    rowsPenghuni () {
      const search = this.search
      const items = this.penghuni
      if (search != null) {
        return _.filter(items, (item) => {
          return item.nama.toLowerCase().includes(search) || item.email.toLowerCase().includes(search) || item.telp.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  methods: {
    addRow () {
      const row = {
        uuid: uuid(),
        nama: '',
        telp: '',
        email: '',
        status_keluarga: '',
        alamat: ''
      }
      this.search = null
      this.penghuni.unshift(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.penghuni, item => item.uuid === uuid)
      this.penghuni.splice(index, 1)
    },

    clearRows () {
      this.penghuni = []
    },

    commitDataToStore: _.debounce(function () {
      const payload = this.buildPayload()
      this.$store.commit('marketing/kontrakAdd/SET_TAB_PENGHUNI', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 2)
    },

    next () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 4)
    },

    buildPayload () {
      return this.penghuni.map(it => {
        return {
          nama: it.nama,
          telp: it.telp,
          email: it.email,
          status_keluarga: it.status_keluarga,
          alamat: it.alamat
        }
      })
    }
  }
}
</script>
