<template>
  <div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">No. Kontrak</label>
        <vs-input class="w-full" v-model="data.no_kontrak" placeholder="Generate Otomatis" disabled/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl. Kontrak *</label>
        <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Masa Garansi (hari) *</label>
        <vs-input class="w-full" type="number" v-model="data.masa_garansi"/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Durasi Penyerahan (bulan) *</label>
        <vs-input class="w-full" type="number" v-model="data.durasi_penyerahan"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Keterangan Kontrak</label>
        <vs-textarea class="w-full" v-model="data.keterangan_kontrak"/>
      </div>
    </div>

    <vs-divider> Detail Properti </vs-divider>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" v-model="data.unit.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Properti Unit</label>
        <vs-input class="w-full" v-model="data.unit.no_unit" readonly/>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Properti Kategori</label>
        <vs-input class="w-full" v-model="data.unit.nama_properti_kategori" readonly/>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Properti Tipe</label>
        <vs-input class="w-full" v-model="data.unit.nama_properti_tipe" readonly/>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Cluster</label>
        <vs-input class="w-full" v-model="data.unit.nama_cluster" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Luas Bangunan (m<sup>2</sup>)</label>
        <vs-input class="w-full" v-model="data.unit.luas_bangunan" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Luas Tanah (m<sup>2</sup>)</label>
        <vs-input class="w-full" v-model="data.unit.luas_tanah" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Luas Efektif (m<sup>2</sup>)</label>
        <vs-input class="w-full" v-model="data.unit.luas_efektif" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">View</label>
        <vs-input class="w-full" v-model="data.unit.view" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Lokasi</label>
        <vs-input class="w-full" v-model="data.unit.lokasi" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Spesifikasi</label>
        <vs-input class="w-full" v-model="data.unit.spesifikasi" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-input class="w-full" v-model="data.unit.keterangan" readonly/>
      </div>
    </div>

    <div class="vx-row float-right mt-6">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'TabKontrak',
  components: {
    flatPickr,
    ValidationErrors
  },
  computed: {
    errors () {
      return this.$store.state.marketing.kontrakAdd.errors.tabKontrak.messages
    },
    storeTabCustomer () {
      return this.$store.state.marketing.kontrakAdd.tabCustomer
    }
  },
  watch: {
    storeTabCustomer (newVal, oldVal) {
      this.onChangeStoreTabCustomer(newVal, oldVal)
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      isLoading: false,
      data: {
        tgl: moment().format('YYYY-MM-DD'),
        masa_garansi: null,
        durasi_penyerahan: null,
        keterangan_kontrak: null,
        unit: {}
      }
    }
  },

  methods: {
    onChangeStoreTabCustomer (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        const booking = _.cloneDeep(newVal.booking)
        if (booking.id_properti_unit) {
          this.getDataPropertiUnit(booking)
        }
      }
    },

    getDataPropertiUnit (booking) {
      PropertiUnitRepository.show(booking.id_properti_unit)
        .then(response => {
          this.data.unit = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function () {
      const payload = this.buildPayload()
      this.$store.commit('marketing/kontrakAdd/SET_TAB_KONTRAK', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 0)
    },

    next () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 2)
    },

    buildPayload () {
      return {
        tgl: this.data.tgl,
        masa_garansi: this.data.masa_garansi,
        durasi_penyerahan: this.data.durasi_penyerahan,
        keterangan_kontrak: this.data.keterangan_kontrak,
        unit: this.data.unit
      }
    }
  }
}
</script>
