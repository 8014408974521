<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-warning con-icon" v-if="!data.id_properti_unit">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Pilih unit terlebih dahulu pada tab <strong>Kontrak & Properti</strong>.
          </div>
        </div>
      </div>
    </div>

    <div :class="{'opacity-50 pointer-events-none': !data.id_properti_unit}">
      <vs-divider class="mt-base">Pembayaran</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Rencana Bayar *</label>
          <v-select :options="caraBayars" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_cara_bayar"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Bank *</label>
          <v-select :options="bankKprs" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_bank_kpr"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">COA Debet *</label>
          <vx-input-group>
            <vs-input placeholder="Pilih COA" :value="data.nama_coa_debet" @click="modalCoa.active = true" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalCoa.active = true"/>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>

      <div :class="{'opacity-50 pointer-events-none': !data.id_cara_bayar}">
        <vs-divider class="mt-24">Harga Unit</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">HPP Tanah</label>
            <v-money class="w-full" v-model="defaultHargaUnit.hpp_tanah" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">HPP Bangunan</label>
            <v-money class="w-full" v-model="defaultHargaUnit.hpp_bangunan" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total HPP</label>
            <v-money class="w-full" :value="(defaultHargaUnit.hpp_tanah + defaultHargaUnit.hpp_bangunan)" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Harga Standar *</label>
            <v-money class="w-full" v-model="data.harga_standar" :readonly="!$can('update_harga_unit')"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Diskon</label>
            <v-input-percentage :isDefaultPercentage="false" :total="data.harga_standar" :percentage.sync="data.diskon_persen" :amountage.sync="data.diskon_jml" :danger="isDiskonError" :danger-text="errorTextDiskon"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Harga Standar (Setelah Diskon)</label>
            <v-money class="w-full" :value="data.harga_standar - data.diskon_jml" disabled/>
          </div>
        </div>
        <div class="vx-row mb-1 mt-1">
          <div class="vx-col sm:w-12/12 w-full">
            <div class="flex flex-wrap items-center space-x-2">
              <div class="flex-none">
                <p class="text-xs mb-1">Biaya Unit</p>
                <vs-button color="success" icon-pack="feather" size="small" icon="icon-plus" @click="addRowBiayaUnit" class="py-2 px-3 mr-2">Add</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-1 mt-3">
          <div class="vx-col sm:w-12/12 w-full">
            <vs-table :data="data.listBiayaUnit" stripe class="fix-table-input-background">
              <template slot="thead">
                <vs-th class="whitespace-no-wrap">#</vs-th>
                <vs-th class="whitespace-no-wrap">Action</vs-th>
                <vs-th class="whitespace-no-wrap">Jadikan Termin</vs-th>
                <vs-th class="whitespace-no-wrap">Tipe</vs-th>
                <vs-th class="whitespace-no-wrap">Deskripsi</vs-th>
                <vs-th class="whitespace-no-wrap">Biaya</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td class="w-8">{{ index + 1 }}</vs-td>
                  <vs-td class="w-20">
                    <div class="flex items-center space-x-2">
                      <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRowBiayaUnit(item.uuid)"/>
                    </div>
                  </vs-td>
                  <vs-td class="w-40">
                    <vs-checkbox class="p-0" v-model="item.is_as_termin" size="small"/>
                  </vs-td>
                  <vs-td class="w-64">
                    <vs-select autocomplete v-model="item.type" class="w-full" @input="onTypeBiayaUnitChanged(item)">
                      <vs-select-item v-for="(it, index) in listTipeBiayaUnit" :key="index" :value="it.type" :text="it.label"/>
                    </vs-select>
                  </vs-td>
                  <vs-td style="width: 16.58rem;">
                    <vs-input class="w-full" v-model="item.description"/>
                  </vs-td>
                  <vs-td>
                    <v-money class="w-full" v-model="item.amount"/>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-8/12 w-full">
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total Biaya Unit</label>
            <v-money class="w-full" :value="totalBiayaUnit" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-8/12 w-full">
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs block">Total Harga Unit<span class="opacity-50 inline-block">(Harga Standar Setelah Diskon + Total Biaya Unit)</span></label>
            <v-money class="w-full" :value="totalHargaUnit" :danger="isHargaUnitError" :danger-text="errorTextHargaUnit" disabled/>
          </div>
        </div>

        <vs-divider class="mt-24">Pajak</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">DPP</label>
            <v-money class="w-full" :value="dpp" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">PPN</label>
            <v-input-percentage :total="dpp" :percentage.sync="data.ppn_persen" :amountage.sync="data.ppn_jml" :danger="isPpnError" :danger-text="errorTextPpn"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">PPh Jenis *</label>
            <vs-select class="w-full" autocomplete :value="data.pph_key" @input="onChangePphJenis">
              <vs-select-item v-for="(item, index) in listPphJenis" :key="index" :value="item.key" :text="item.alias + ' - ' + item.value + '%'"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">PPh *</label>
            <v-input-percentage class="w-full" :total="dpp" :percentage.sync="data.pph_persen" :amountage.sync="data.pph_jml" :disabled="true"/>
          </div>
        </div>

        <vs-divider class="mt-24">Biaya Tambahan</vs-divider>
        <div class="vx-row mb-1 mt-1">
          <div class="vx-col sm:w-12/12 w-full">
            <div class="flex flex-wrap items-center space-x-2">
              <div class="flex-none">
                <vs-button color="success" icon-pack="feather" size="small" icon="icon-plus" @click="addRowBiayaTambahan" class="py-2 px-3 mr-2">Add</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-1 mt-3">
          <div class="vx-col sm:w-12/12 w-full">
            <vs-table :data="data.listBiayaTambahan" stripe class="fix-table-input-background">
              <template slot="thead">
                <vs-th class="whitespace-no-wrap">#</vs-th>
                <vs-th class="whitespace-no-wrap">Action</vs-th>
                <vs-th class="whitespace-no-wrap">Jadikan Termin</vs-th>
                <vs-th class="whitespace-no-wrap">Tipe</vs-th>
                <vs-th class="whitespace-no-wrap">Deskripsi</vs-th>
                <vs-th class="whitespace-no-wrap">Biaya</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td class="w-8">{{ index + 1 }}</vs-td>
                  <vs-td class="w-20">
                    <div class="flex items-center space-x-2">
                      <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRowBiayaTambahan(item.uuid)"/>
                    </div>
                  </vs-td>
                  <vs-td class="w-40">
                    <vs-checkbox class="p-0" v-model="item.is_as_termin" size="small"/>
                  </vs-td>
                  <vs-td class="w-64">
                    <vs-select autocomplete v-model="item.type" class="w-full" @input="onTypeBiayaTambahanChanged(item)">
                      <vs-select-item v-for="(it, index) in listTipeBiayaTambahan" :key="index" :value="it.type" :text="it.label"/>
                    </vs-select>
                  </vs-td>
                  <vs-td style="width: 16.58rem;">
                    <vs-input class="w-full" v-model="item.description"/>
                  </vs-td>
                  <vs-td>
                    <v-money class="w-full" v-model="item.amount"/>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-8/12 w-full">
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total Biaya Tambahan</label>
            <v-money class="w-full" :value="totalBiayaTambahan" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-8/12 w-full">
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs block"><span class="text-warning block">Total Harga Jual</span><span class="opacity-50 inline-block">(DPP + PPN + Total Biaya Tambahan)</span></label>
            <v-money class="w-full" :value="totalHargaJual" disabled/>
          </div>
        </div>

        <vs-divider class="mt-24">Uang Muka</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Uang Muka</label>
            <v-input-percentage :total="totalHargaJual" :percentage.sync="data.dp_persen" :amountage.sync="data.dp_jml" :danger="isDpError" :danger-text="errorTextDP"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Termin Uang Muka ( X )</label>
            <vs-input class="w-full" type="number" v-model="data.termin_dp" :danger="isTerminDpError" :danger-text="errorTextTerminDP" :disabled="!data.dp_jml > 0"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total Uang Muka</label>
            <v-money class="w-full" :value="totalUangMuka" disabled/>
          </div>
        </div>

        <vs-divider class="mt-24">Angsuran</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Sisa Angsuran</label>
            <v-money class="w-full" :value="totalSisaAngsuran" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Termin Angsuran ( X ) *</label>
            <vs-input class="w-full" type="number" v-model="data.termin_angsuran" :danger="isTerminAngsuranError" :danger-text="errorTextTerminAngsuran"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Mulai Jatuh Tempo *</label>
            <flat-pickr class="w-full" v-model="data.angsuran_start_due"></flat-pickr>
          </div>
        </div>

        <vs-divider class="mt-base">Review Termin</vs-divider>
        <span class="mt-1 mb-4 mx-1 text-primary underline text-sm cursor-pointer w-auto" :class="{'invisible': isTerminEditable}" @click="isTerminEditable = true">
          Edit Termin
        </span>
        <div class="vx-row mb-3" v-if="isTerminEditable">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex space-x-3">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" :disabled="!isCanGenerateTermins" @click="generateTermins" class="py-2 px-3">Generate Termin</vs-button>
                  <vs-button color="primary" icon-pack="feather" icon="icon-refresh-cw" :disabled="!isRefreshTerminNeeded" @click="refreshTermins" class="py-2 px-3">Refresh Termin</vs-button>
                  <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.angsurans.length < 1" @click="clearTermins" class="py-2 px-3">Clear</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vs-table :data="data.angsurans" stripe class="fix-table-input-background">
          <template slot="thead" class="text-sm">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Termin Ke</vs-th>
            <vs-th class="whitespace-no-wrap">Tipe</vs-th>
            <vs-th class="whitespace-no-wrap text-right">Persen Bayar</vs-th>
            <vs-th class="whitespace-no-wrap text-right">Jumlah Bayar</vs-th>
            <vs-th class="whitespace-no-wrap text-right">DPP</vs-th>
            <vs-th class="whitespace-no-wrap text-right">PPN</vs-th>
            <vs-th class="whitespace-no-wrap text-right">PPh</vs-th>
            <vs-th class="whitespace-no-wrap">Due Date</vs-th>
            <vs-th class="whitespace-no-wrap text-right">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>{{ index + 1 }}</vs-td>
              <vs-td><span class="font-bold" :class="['text-' + getAngsuranColor(item)]">Ke - {{ item.no_termin }}</span></vs-td>
              <vs-td>
                <span class="inline-flex items-center whitespace-no-wrap justify-center p-2 text-xs leading-none text-white rounded-full" :class="['bg-' + getAngsuranColor(item)]">
                  {{ item.tipe_angsuran }}
                </span>
              </vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.persen | idr(2) }}%</vs-td>
              <vs-td class="whitespace-no-wrap text-right">
                <v-money class="w-48" theme="theme4" v-model="item.jml_nominal" @input="updateTerminItemCalculation(index)" :disabled="item.tipe_angsuran === 'BOOKING FEE' || !isTerminEditable"/>
                <p v-if="index === $data.data.angsurans.length - 1 && isTerminTotalNominalNotBalance"
                   @click="adjustNominalForLastTermin"
                   class="text-xs underline text-primary text-right cursor-pointer">Set Sisa</p>
              </vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.dpp | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_ppn | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_pph | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <div class="w-40">
                  <flat-pickr class="w-full" v-model="item.tgl_due" :disabled="!isTerminEditable"></flat-pickr>
                </div>
              </vs-td>
              <vs-td class="whitespace-no-wrap"><vs-input class="w-64" placeholder="Keterangan" v-model="item.keterangan" :disabled="!isTerminEditable"/></vs-td>
            </vs-tr>
            <!--footer-->
            <vs-tr v-if="$data.data.angsurans.length > 0" class="text-sm">
              <vs-td class="font-bold">Total</vs-td>
              <vs-td class="text-right font-bold" colspan="3" ><span>{{ footerTermin.totalPercent | idr }}%</span></vs-td>
              <vs-td class="text-right font-bold"><span class="mr-3" :class="{'text-danger': isTerminTotalNominalNotBalance}">{{ footerTermin.totalNominal | idr }}</span></vs-td>
              <vs-td class="text-right font-bold">{{ footerTermin.totalDpp | idr }}</vs-td>
              <vs-td class="text-right font-bold">{{ footerTermin.totalPpn | idr }}</vs-td>
              <vs-td class="text-right font-bold">{{ footerTermin.totalPph | idr }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="vx-row float-right mt-6">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data COA"
              :active="modalCoa.active"
              v-on:update:active="modalCoa.active = $event">
      <Coa :externalFilter="{ id: 137 }" :selectable="true" @selected="onSelectedModalCoa"/>
    </vs-popup>

  </div>
</template>

<script>
import Coa from '@/views/pages/master/coa/Coa'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VInputPercentage from '@/views/components/v-input-percentage/VInputPercentage'
import VMoney from '@/views/components/v-money/VMoney'
import tabAngsuranMixin from '@/views/pages/marketing/booking/mixins/tab-angsuran/tabAngsuranMixin'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'
import colorMixin from '@/views/pages/marketing/booking/mixins/tab-angsuran/colorMixin'
import { v4 as uuid } from 'uuid'

export default {
  name: 'TabAngsuran',
  mixins: [tabAngsuranMixin, colorMixin],
  components: {
    ValidationErrors,
    VInputPercentage,
    flatPickr,
    VMoney,
    Coa,
    'v-select': vSelect
  },
  computed: {
    errors () {
      return this.$store.state.marketing.kontrakAdd.errors.tabAngsuran.messages
    },
    storeTabCustomer () {
      return this.$store.state.marketing.kontrakAdd.tabCustomer
    }
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    },
    storeTabCustomer (newVal, oldVal) {
      this.onChangeStoreTabCustomer(newVal, oldVal)
    }
  },
  data () {
    return {
      isTerminEditable: false
    }
  },
  methods: {
    onChangeStoreTabCustomer (newVal, oldVal) {
      const booking = _.cloneDeep(newVal.booking)
      const isSameBooking = newVal.booking.id === (oldVal.booking ? oldVal.booking.id : null)
      if (!isSameBooking) {
        this.initDataFromBooking(booking)
      }
    },

    commitDataToStore: _.debounce(function () {
      const payload = this.buildPayload()
      this.$store.commit('marketing/kontrakAdd/SET_TAB_ANGSURAN', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 1)
    },

    next () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 3)
    },

    initDataFromBooking (booking) {
      const listBiaya = booking.list_biaya.map(it => {
        return {
          uuid: uuid(),
          id: it.id,
          group: it.group,
          type: it.type,
          description: it.description,
          amount: it.amount,
          is_as_termin: it.is_as_termin
        }
      })

      this.data = {
        id_booking: booking.id,
        id_properti_unit: booking.id_properti_unit,
        id_coa_debet: booking.id_coa_debet,
        nama_coa_debet: booking.nama_coa_debet,
        id_cara_bayar: booking.id_cara_bayar,
        id_bank_kpr: booking.id_bank_kpr,
        harga_standar: booking.harga_standar,
        diskon_persen: booking.diskon_persen,
        diskon_jml: booking.diskon_jml,
        ppn_persen: booking.ppn_persen,
        ppn_jml: booking.ppn_jml,
        pph_persen: booking.pph_persen,
        pph_jml: booking.pph_jml,
        pph_key: booking.pph_key,
        pph_nama: booking.pph_nama,
        termin_dp: booking.termin_dp,
        dp_persen: booking.dp_persen,
        dp_jml: booking.dp_jml,
        termin_angsuran: booking.termin_angsuran,
        angsuran_start_due: booking.angsuran_start_due,
        angsurans: booking.angsurans.map((it) => {
          return {
            id: it.id,
            group: it.group,
            tipe_angsuran: it.tipe_angsuran,
            no_termin: it.no_termin,
            tgl_due: it.tgl_due,
            persen: it.persen,
            jml_nominal: it.jml_nominal,
            dpp: it.dpp,
            jml_ppn: it.jml_ppn,
            jml_pph: it.jml_pph,
            keterangan: it.keterangan
          }
        }),
        listBiayaUnit: listBiaya.filter(it => it.group === 'BIAYA UNIT'),
        listBiayaTambahan: listBiaya.filter(it => it.group === 'BIAYA TAMBAHAN'),
        angsuranDeletedIds: [],
        listBiayaDeletedIds: []
      }
    },

    buildPayload () {
      return {
        id_properti_unit: this.data.id_properti_unit,
        id_cara_bayar: this.data.id_cara_bayar,
        id_bank_kpr: this.data.id_bank_kpr,
        id_coa_debet: this.data.id_coa_debet,
        nama_cara_bayar: _.find(this.caraBayars, it => it.id === this.data.id_cara_bayar).nama,

        harga_min: this.defaultHargaUnit.harga_min,
        harga_max: this.defaultHargaUnit.harga_max,
        total_hpp_tanah: this.defaultHargaUnit.hpp_tanah,
        total_hpp_bangunan: this.defaultHargaUnit.hpp_bangunan,
        harga_standar: this.data.harga_standar,
        diskon_jml: this.data.diskon_jml || 0,
        diskon_persen: this.data.diskon_persen || 0,
        total_biaya_unit: this.totalBiayaUnit,
        total_harga_unit: this.totalHargaUnit,

        dpp: this.dpp,
        ppn_jml: this.data.ppn_jml || 0,
        ppn_persen: this.data.ppn_persen || 0,
        pph_jml: this.data.pph_jml || 0,
        pph_persen: this.data.pph_persen || 0,
        pph_nama: this.data.pph_nama,
        pph_key: this.data.pph_key,

        total_biaya_tambahan: this.totalBiayaTambahan,
        total_harga_jual: this.totalHargaJual,

        dp_jml: this.data.dp_jml,
        dp_persen: this.data.dp_persen,
        termin_dp: this.data.termin_dp,

        total_sisa_angsuran: this.totalSisaAngsuran,
        angsuran_start_due: this.data.angsuran_start_due,
        termin_angsuran: this.data.termin_angsuran,

        listBiayaDeletedIds: this.data.listBiayaDeletedIds,
        listBiaya: [...this.data.listBiayaUnit, ...this.data.listBiayaTambahan].map(item => {
          return {
            id: item.id,
            group: item.group,
            type: item.type,
            description: item.description,
            amount: item.amount || 0,
            is_as_termin: item.is_as_termin ? 1 : 0
          }
        }),

        is_refresh_termin_needed: this.isRefreshTerminNeeded ? 1 : 0,
        angsuranDeletedIds: this.data.angsuranDeletedIds,
        angsurans: this.data.angsurans.map(item => {
          return {
            id: item.id,
            no_termin: item.no_termin,
            group: item.group,
            tipe_angsuran: item.tipe_angsuran,
            persen: item.persen,
            jml_nominal: item.jml_nominal || 0,
            dpp: item.dpp || 0,
            jml_ppn: item.jml_ppn || 0,
            jml_pph: item.jml_pph || 0,
            tgl_due: item.tgl_due,
            keterangan: item.keterangan
          }
        })
      }
    }
  }
}
</script>
