import _ from 'lodash'

const errors = {
  global: {
    isError: false,
    messages: null
  },
  tabCustomer: {
    isError: false,
    messages: null
  },
  tabKontrak: {
    isError: false,
    messages: null
  },
  tabAngsuran: {
    isError: false,
    messages: null
  },
  tabPenghuni: {
    isError: false,
    messages: null
  },
  tabDokumenCustomer: {
    isError: false,
    messages: null
  },
  tabHadiah: {
    isError: false,
    messages: null
  },
  tabKomisi: {
    isError: false,
    messages: null
  }
}

const getDefaultState = () => {
  return {
    // global
    activeTab: 0,
    errors: errors,
    isAnyUnsavedChanges: false,

    // tab customer
    tabCustomer: {},

    // tab kontrak
    tabKontrak: {},

    // tab angsuran
    tabAngsuran: {},

    // tab penghuni
    tabPenghuni: [],

    // tab dokumen customer
    tabDokumenCustomer: [],

    // tab hadiah
    tabHadiah: [],

    // tab komisi
    tabKomisi: []
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_ERRORS (state, payload) {
      const errors = payload
      const globalErrors = errors
      const tabCustomerErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabCustomer'))
      const tabKontrakErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabKontrak'))
      const tabAngsuranErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabAngsuran'))
      const tabPenghuniErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabPenghuni'))
      const tabDokumenCustomerErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabDokumenCustomer'))
      const tabHadiahErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabHadiah'))
      const tabKomisiErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabKomisi'))

      state.errors.global.messages = globalErrors
      state.errors.tabCustomer.messages = tabCustomerErrors
      state.errors.tabKontrak.messages = tabKontrakErrors
      state.errors.tabAngsuran.messages = tabAngsuranErrors
      state.errors.tabPenghuni.messages = tabPenghuniErrors
      state.errors.tabDokumenCustomer.messages = tabDokumenCustomerErrors
      state.errors.tabHadiah.messages = tabHadiahErrors
      state.errors.tabKomisi.messages = tabKomisiErrors

      state.errors.global.isError = !_.isEmpty(globalErrors)
      state.errors.tabCustomer.isError = !_.isEmpty(tabCustomerErrors)
      state.errors.tabKontrak.isError = !_.isEmpty(tabKontrakErrors)
      state.errors.tabAngsuran.isError = !_.isEmpty(tabAngsuranErrors)
      state.errors.tabPenghuni.isError = !_.isEmpty(tabPenghuniErrors)
      state.errors.tabDokumenCustomer.isError = !_.isEmpty(tabDokumenCustomerErrors)
      state.errors.tabHadiah.isError = !_.isEmpty(tabHadiahErrors)
      state.errors.tabKomisi.isError = !_.isEmpty(tabKomisiErrors)
    },
    CLEAR_ERRORS (state) {
      state.errors = _.cloneDeep(errors)
    },
    SET_ANY_UNSAVED_CHANGES (state, isAnyUnsavedChanges) {
      state.isAnyUnsavedChanges = isAnyUnsavedChanges
    },
    SET_TAB_CUSTOMER (state, payload) {
      state.tabCustomer = payload
    },
    SET_TAB_KONTRAK (state, payload) {
      state.tabKontrak = payload
    },
    SET_TAB_ANGSURAN (state, payload) {
      state.tabAngsuran = payload
    },
    SET_TAB_PENGHUNI (state, payload) {
      state.tabPenghuni = payload
    },
    SET_TAB_DOKUMEN_CUSTOMER (state, payload) {
      state.tabDokumenCustomer = payload
    },
    SET_TAB_HADIAH (state, payload) {
      state.tabHadiah = payload
    },
    SET_TAB_KOMISI (state, payload) {
      state.tabKomisi = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
