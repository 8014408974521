<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-warning con-icon" v-if="!data.id_properti_unit">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Pilih <strong>Properti Unit</strong> terlebih dahulu pada tab <strong>Kontrak & Properti</strong>.
          </div>
        </div>
        <div class="con-vs-alert con-vs-alert-warning con-icon mt-2" v-if="!data.nama_cara_bayar">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Pilih <strong>Rencana Bayar</strong> terlebih dahulu pada tab <strong>Angsuran</strong>.
          </div>
        </div>
      </div>
    </div>

    <div :class="{'opacity-50 pointer-events-none': !data.id_properti_unit || !data.nama_cara_bayar}">
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Tgl</label>
          <flat-pickr class="w-full" v-model="data.tgl_komisi" disabled></flat-pickr>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Marketing (Dealer)</label>
          <div @click="modalStafDealer.active = true">
            <vx-input-group>
              <vs-input v-model="data.nama_staf_dealer" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Koordinator</label>
          <vs-input class="w-full" v-model="data.nama_staf_atasan" disabled/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Proyek</label>
          <vs-input class="w-full" v-model="data.nama_proyek" disabled/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Rencana Bayar</label>
          <vs-input class="w-full" v-model="data.nama_cara_bayar" disabled/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Total Nilai Kontrak</label>
          <v-money class="w-full" v-model="data.total_harga_jual" disabled/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">DPP</label>
          <v-money class="w-full" :value="storeTabAngsuran.dpp" disabled/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">PPh</label>
          <v-money class="w-full" :value="storeTabAngsuran.pph_jml" disabled/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">BPHTB</label>
          <v-money class="w-full" :value="bphtb" disabled/>
        </div>
      </div>

      <vs-divider class="mt-base"> Staf Penerima Komisi </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <div class="flex justify-center">
            <vs-button type="flat" icon-pack="feather" icon="icon-download-cloud" @click="getPenerimaKomisi" :disabled="isLoadingGetKomisi">
              <span v-if="isLoadingGetKomisi">Loading...</span>
              <span class="animate-pulsa" v-else-if="data.listPenerimaKomisi.length < 1">Load Data Penerima Komisi</span>
              <span v-else-if="data.listPenerimaKomisi.length > 0">Refresh Data Penerima Komisi</span>
            </vs-button>
          </div>
          <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoadingGetKomisi }"/>
          <vs-table :data="data.listPenerimaKomisi" stripe class="fix-table-input-background">
            <template slot="thead">
              <vs-th class="whitespace-no-wrap text-center">#</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Nama Staf</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Jabatan</vs-th>
              <vs-th class="whitespace-no-wrap text-center">NPWP</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Komisi</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Harga Dasar Komisi</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Closing Fee</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Total Komisi</vs-th>
              <vs-th class="whitespace-no-wrap text-center">PPh</vs-th>
              <vs-th class="whitespace-no-wrap text-center">PPh Tarif</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Ketentuan</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr class="text-sm" v-for="(item, index) in data" :key="item.uuid">
                <vs-td>{{ index + 1 }}</vs-td>
                <vs-td class="whitespace-no-wrap">
                  <div class="w-48" v-if="item.isRowLocked">
                    <vx-input-group>
                      <vs-input v-model="item.nama_staf" readonly/>
                    </vx-input-group>
                  </div>
                  <div class="w-48" v-else>
                    <vx-input-group>
                      <vs-input v-model="item.nama_staf" @click="showModalStaf(item)" readonly/>
                      <template slot="append">
                        <div class="append-text btn-addon">
                          <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="showModalStaf(item)"/>
                        </div>
                      </template>
                    </vx-input-group>
                  </div>
                </vs-td>
                <vs-td class="whitespace-no-wrap">{{ item.nama_role }}</vs-td>
                <vs-td class="whitespace-no-wrap">{{ item.npwp || '-' }}</vs-td>
                <vs-td class="whitespace-no-wrap">{{ item.persen_komisi }}%</vs-td>
                <vs-td class="whitespace-no-wrap text-right">{{ item.harga_dasar_komisi | idr }}</vs-td>
                <vs-td class="whitespace-no-wrap text-right">{{ item.closing_fee | idr }}</vs-td>
                <vs-td class="whitespace-no-wrap text-right">{{ item.total_komisi | idr }}</vs-td>
                <vs-td class="whitespace-no-wrap text-right">{{ item.pph_jml | idr }}</vs-td>
                <vs-td class="whitespace-no-wrap">{{ item.pph_persen }}%</vs-td>
                <vs-td class="whitespace-no-wrap"><span class="whitespace-no-wrap text-primary font-semibold underline text-left cursor-pointer" @click="showModalTermin(item)">{{ item.termins.length }} Ketentuan</span></vs-td>
              </vs-tr>
              <vs-tr v-if="data.length > 0" class="text-sm font-bold">
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td class="text-right">{{ footer.grandTotalHargaDasarKomisi | idr }}</vs-td>
                <vs-td class="text-right">{{ footer.grandTotalClosingFee | idr }}</vs-td>
                <vs-td class="text-right">{{ footer.grandTotalKomisi | idr }}</vs-td>
                <vs-td class="text-right">{{ footer.grandTotalPph | idr }}</vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full flex">
          <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
          <vs-button icon-pack="feather" icon="icon-arrow-right" icon-after disabled>Lanjut</vs-button>
        </div>
      </div>
    </div>

    <!--modal staf dealer-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Staf Dealer"
              :active="modalStafDealer.active"
              v-on:update:active="modalStafDealer.active = $event">
      <Staf :selectable="true"
            @selected="onSelectedModalStafDealer"/>
    </vs-popup>

    <!--modal staf-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Staf"
              :active="modalStaf.active"
              v-on:update:active="modalStaf.active = $event">
      <Staf ref="modalStaf"
            :selectable="true"
            :already-selected-rows="modalStafAlreadySelectedRows"
            :external-filter="modalStaf.filter"
            @selected="onSelectedModalStaf"/>
    </vs-popup>

    <!--modal termin komisi-->
    <TerminKomisi :isActive.sync="modalTermin.active" :termins="modalTermin.termins"/>
  </div>
</template>

<script>
import _ from 'lodash'
import flatPickr from 'vue-flatpickr-component'
import Staf from '@/views/pages/master/staf/Staf'
import moment from 'moment'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import TerminKomisi from '@/views/pages/marketing/kontrak/tabs/TerminKomisi'
import MarketingFeeRepository from '@/repositories/marketing/marketing-fee-repository'
import StafRepository from '@/repositories/master/staf-repository'
import { v4 as uuid } from 'uuid'

export default {
  name: 'TabKomisi',
  components: {
    Staf,
    TerminKomisi,
    flatPickr,
    ValidationErrors,
    VMoney
  },
  computed: {
    errors () {
      return this.$store.state.marketing.kontrakAdd.errors.tabKomisi.messages
    },
    storeTabCustomer () {
      return this.$store.state.marketing.kontrakAdd.tabCustomer
    },
    storeTabKontrak () {
      return this.$store.state.marketing.kontrakAdd.tabKontrak
    },
    storeTabAngsuran () {
      return this.$store.state.marketing.kontrakAdd.tabAngsuran
    },
    modalStafAlreadySelectedRows () {
      return _.map(this.data.listPenerimaKomisi, item => item.id_staf)
    },
    bphtb () {
      const dpp = this.storeTabAngsuran.dpp || 0
      const npoptkp = this.$systemParam('pph_pasal4_ayat2_bphtb_pengurang') // 60.000.000
      const persenTarifBphtb = this.$systemParam('pph_pasal4_ayat2_bphtb_pengali') / 100 // 5%
      if (dpp <= npoptkp) {
        return 0
      } else {
        return (dpp - npoptkp) * persenTarifBphtb
      }
    },
    footer () {
      return {
        grandTotalHargaDasarKomisi: _.sumBy(this.data.listPenerimaKomisi, item => item.harga_dasar_komisi),
        grandTotalClosingFee: _.sumBy(this.data.listPenerimaKomisi, item => item.closing_fee),
        grandTotalKomisi: _.sumBy(this.data.listPenerimaKomisi, item => item.total_komisi),
        grandTotalPph: _.sumBy(this.data.listPenerimaKomisi, item => item.pph_jml)
      }
    }
  },
  watch: {
    storeTabCustomer (newVal, oldVal) {
      this.onChangeStoreTabCustomer(newVal, oldVal)
    },
    storeTabKontrak (newVal, oldVal) {
      this.onChangeStoreTabKontrak(newVal, oldVal)
    },
    storeTabAngsuran (newVal, oldVal) {
      this.onChangeStoreTabAngsuran(newVal, oldVal)
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      isLoadingGetKomisi: false,
      modalStafDealer: {
        active: false
      },
      modalStaf: {
        active: false,
        rowUuid: null,
        filter: {}
      },
      modalTermin: {
        active: false,
        rowUuid: null,
        termins: []
      },
      data: {
        id_staf_dealer: null,
        nama_staf_dealer: null,
        nama_staf_atasan: null,
        npwp_staf_dealer: null,
        tgl_komisi: moment().format('YYYY-MM-DD'),
        nama_proyek: null,
        nama_cara_bayar: null,
        total_harga_jual: 0,
        listPenerimaKomisi: []
      }
    }
  },
  methods: {
    onChangeStoreTabCustomer (newVal, oldVal) {
      if (newVal.booking) {
        // refresh penerima komisi
        const idStaf = newVal.booking.id_staf
        if (idStaf !== this.data.id_staf_dealer) {
          this.data.listPenerimaKomisi = []
          this.getStafBookingData(idStaf)
        }
      }
    },

    onChangeStoreTabKontrak (newVal) {
      this.data.nama_proyek = newVal.unit.nama_proyek
    },

    onChangeStoreTabAngsuran (newVal) {
      this.data.id_properti_unit = newVal.id_properti_unit
      this.data.nama_cara_bayar = newVal.nama_cara_bayar
      this.data.total_harga_jual = newVal.total_harga_jual
    },

    getStafBookingData (idStaf) {
      this.isLoadingGetKomisi = true

      StafRepository.show(idStaf)
        .then(response => {
          this.setDataDealer(response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingGetKomisi = false
        })
    },

    getPenerimaKomisi () {
      this.isLoadingGetKomisi = true

      const params = {
        id_proyek: this.storeTabKontrak.unit.id_proyek,
        id_properti_tipe: this.storeTabKontrak.unit.id_properti_tipe,
        id_cara_bayar: this.storeTabAngsuran.id_cara_bayar,
        id_role_dealer: this.data.id_role_dealer
      }
      MarketingFeeRepository.getReceiver(params)
        .then(response => {
          this.buildDataListPenerimaKomisi(response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingGetKomisi = false
        })
    },

    buildDataListPenerimaKomisi (items) {
      const dpp = this.storeTabAngsuran.dpp
      const pphJml = this.storeTabAngsuran.pph_jml
      const bphtb = this.bphtb
      const hargaDasarKomisi = dpp - pphJml - bphtb

      this.data.listPenerimaKomisi = _.map(items, item => {
        const additionalKeys = {}
        additionalKeys.uuid = uuid()
        additionalKeys.isRowLocked = this.data.id_role_dealer === item.id_role
        additionalKeys.id_staf = this.data.id_role_dealer === item.id_role ? this.data.id_staf_dealer : null
        additionalKeys.nama_staf = this.data.id_role_dealer === item.id_role ? this.data.nama_staf_dealer : null
        additionalKeys.npwp = this.data.id_role_dealer === item.id_role ? this.data.npwp_staf_dealer : null
        additionalKeys.pph_key = additionalKeys.npwp ? 'persen_tarif_komisi_npwp' : 'persen_tarif_komisi_non_npwp'
        additionalKeys.pph_persen = this.$systemParam(additionalKeys.pph_key)
        // main calculation
        additionalKeys.persen_komisi = item.komisi_new_persen
        additionalKeys.harga_dasar_komisi = hargaDasarKomisi * (additionalKeys.persen_komisi / 100)
        additionalKeys.closing_fee = item.closing_fee || 0
        additionalKeys.total_komisi = additionalKeys.harga_dasar_komisi + additionalKeys.closing_fee
        additionalKeys.dpp = additionalKeys.total_komisi / ((100 + additionalKeys.pph_persen) / 100) // tidak dipakai, karena pph_jml = total_komisi * pph_persen, bukan dpp * pph_persen
        additionalKeys.pph_jml = additionalKeys.total_komisi * (additionalKeys.pph_persen / 100)
        return _.extend({}, item, additionalKeys)
      })
    },

    setDataDealer (data) {
      this.data.id_staf_dealer = data.id
      this.data.id_role_dealer = data.id_roles
      this.data.nama_staf_dealer = data.nama
      this.data.npwp_staf_dealer = data.no_npwp
      this.data.nama_staf_atasan = data.nama_atasan
    },

    onSelectedModalStafDealer (data) {
      this.setDataDealer(data)
      this.modalStafDealer.active = false
      this.data.listPenerimaKomisi = []
      this.getPenerimaKomisi()
    },

    onSelectedModalStaf (data) {
      const uuid = this.modalStaf.rowUuid
      const index = _.findIndex(this.data.listPenerimaKomisi, item => item.uuid === uuid)
      const item = {
        ...this.data.listPenerimaKomisi[index],
        id_staf: data.id,
        nama_staf: data.nama,
        npwp: data.no_npwp,
        persen_tarif: data.no_npwp ? this.$systemParam('persen_tarif_komisi_npwp') : this.$systemParam('persen_tarif_komisi_non_npwp')
      }
      this.$set(this.data.listPenerimaKomisi, index, item) // fix vue reactivity
      this.modalStaf.active = false
    },

    showModalStaf (item) {
      if (item.id_role !== this.modalStaf.filter.id_role) {
        this.$refs.modalStaf.clearRows()
      }
      this.modalStaf.rowUuid = item.uuid
      this.modalStaf.filter = { id_role: item.id_role }
      this.modalStaf.active = true
    },

    showModalTermin (item) {
      this.modalTermin.rowUuid = item.uuid
      this.modalTermin.termins = item.termins
      this.modalTermin.active = true
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = this.buildPayload()
      this.$store.commit('marketing/kontrakAdd/SET_TAB_KOMISI', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 5)
    },

    buildPayload () {
      return {
        id_staf_dealer: this.data.id_staf_dealer,
        tgl_komisi: this.data.tgl_komisi,
        list_penerima_komisi: this.data.listPenerimaKomisi.map(item => {
          return {
            id_role: item.id_role,
            id_staf: item.id_staf,
            npwp: item.npwp,
            persen_komisi: item.persen_komisi,
            harga_dasar_komisi: item.harga_dasar_komisi,
            closing_fee: item.closing_fee,
            total_komisi: item.total_komisi,
            dpp: item.dpp,
            pph_key: item.pph_key,
            pph_persen: item.pph_persen,
            pph_jml: item.pph_jml,
            keterangan: item.keterangan,
            termins: item.termins.map(it => {
              return {
                no_urut: it.no_urut,
                syarat_cair: it.syarat_cair,
                syarat_cair_persen: it.syarat_cair_persen,
                syarat_cair_description: it.syarat_cair_description,
                komisi_cair: it.komisi_cair,
                komisi_cair_persen: it.komisi_cair_persen,
                komisi_cair_description: it.komisi_cair_description
              }
            })
          }
        })
      }
    }
  }
}
</script>
