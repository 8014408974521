<template>
  <div>
    <vx-card>
      <vs-tabs alignment="fixed" v-model="activeTab">
        <div class="my-2">
          <vs-tab label="Customer"
                  icon-pack="feather"
                  :icon="errors.tabCustomer.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabCustomer.isError ? 'danger' : null">
            <TabCustomer/>
          </vs-tab>

          <vs-tab label="Kontrak & Properti"
                  icon-pack="feather"
                  :icon="errors.tabKontrak.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabKontrak.isError ? 'danger' : null">
            <TabKontrak/>
          </vs-tab>

          <vs-tab label="Angsuran"
                  icon-pack="feather"
                  :icon="errors.tabAngsuran.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabAngsuran.isError ? 'danger' : null">
            <TabAngsuran/>
          </vs-tab>

          <vs-tab label="Penghuni"
                  icon-pack="feather"
                  :icon="errors.tabPenghuni.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabPenghuni.isError ? 'danger' : null">
            <TabPenghuni/>
          </vs-tab>

          <vs-tab label="Dokumen Customer"
                  icon-pack="feather"
                  :icon="errors.tabDokumenCustomer.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabDokumenCustomer.isError ? 'danger' : null">
            <TabDokumenCustomer/>
          </vs-tab>

          <vs-tab label="Hadiah"
                  icon-pack="feather"
                  :icon="errors.tabHadiah.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabHadiah.isError ? 'danger' : null">
            <TabHadiah/>
          </vs-tab>

          <vs-tab label="Komisi"
                  icon-pack="feather"
                  :icon="errors.tabKomisi.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabKomisi.isError ? 'danger' : null">
            <TabKomisi/>
          </vs-tab>

        </div>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 6">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading || !isAnyUnsavedChanges" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import KontrakRepository from '@/repositories/marketing/kontrak/kontrak-repository'
import TabCustomer from '@/views/pages/marketing/kontrak/tabs/TabCustomer'
import TabKontrak from '@/views/pages/marketing/kontrak/tabs/TabKontrak'
import TabAngsuran from '@/views/pages/marketing/kontrak/tabs/TabAngsuran'
import TabPenghuni from '@/views/pages/marketing/kontrak/tabs/TabPenghuni'
import TabDokumenCustomer from '@/views/pages/marketing/kontrak/tabs/TabDokumenCustomer'
import TabHadiah from '@/views/pages/marketing/kontrak/tabs/TabHadiah'
import TabKomisi from '@/views/pages/marketing/kontrak/tabs/TabKomisi'
import moduleKontrakAdd from '@/store/modules/marketing/kontrak/kontrak-add.store'
import { convertToFormData } from '@/utilities/common/global-methods'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'KontrakAdd',
  components: {
    TabDokumenCustomer,
    TabKontrak,
    TabCustomer,
    TabPenghuni,
    TabAngsuran,
    TabHadiah,
    TabKomisi
  },
  mounted () {
    this.$store.commit('marketing/kontrakAdd/SET_ANY_UNSAVED_CHANGES', false)
    this.$store.commit('marketing/kontrakAdd/CLEAR_ERRORS')
    this.listenKeyboardPressed()
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.marketing.kontrakAdd.activeTab
      },
      set (value) {
        this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.marketing.kontrakAdd.errors,
      isAnyUnsavedChanges: state => state.marketing.kontrakAdd.isAnyUnsavedChanges,
      dataTabCustomer: state => state.marketing.kontrakAdd.tabCustomer,
      dataTabKontrak: state => state.marketing.kontrakAdd.tabKontrak,
      dataTabAngsuran: state => state.marketing.kontrakAdd.tabAngsuran,
      dataTabPenghuni: state => state.marketing.kontrakAdd.tabPenghuni,
      dataTabDokumenCustomer: state => state.marketing.kontrakAdd.tabDokumenCustomer,
      dataTabHadiah: state => state.marketing.kontrakAdd.tabHadiah,
      dataTabKomisi: state => state.marketing.kontrakAdd.tabKomisi
    })
  },
  methods: {
    save () {
      this.$store.commit('marketing/kontrakAdd/CLEAR_ERRORS')
      this.isLoading = true

      const params = convertToFormData(this.buildParams())
      KontrakRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.$store.commit('marketing/kontrakAdd/SET_ERRORS', errors)
              this.notifyError('Data tidak valid, mohon periksa kembali.')
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        tabCustomer: _.pick(this.dataTabCustomer, ['id_booking_asal', 'nama_pemesan', 'customer']),
        tabKontrak: this.dataTabKontrak,
        tabAngsuran: this.dataTabAngsuran,
        tabPenghuni: this.dataTabPenghuni,
        tabDokumenCustomer: this.dataTabDokumenCustomer,
        tabHadiah: this.dataTabHadiah,
        tabKomisi: this.dataTabKomisi
      }
    },

    onSuccess () {
      this.$store.commit('marketing/kontrakAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'marketing.kontrak' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    listenKeyboardPressed () {
      window.addEventListener('keypress', e => this.onKeyboardPressed(), { once: true })
    },

    onKeyboardPressed: _.debounce(function () {
      this.$store.commit('marketing/kontrakAdd/SET_ANY_UNSAVED_CHANGES', true)
    }, 500)
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'kontrakAdd'], moduleKontrakAdd)
  },
  beforeDestroy () {
    this.$store.commit('marketing/kontrakAdd/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'kontrakAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
