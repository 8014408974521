<template>
  <div>
    <div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Booking Asal</label>
          <vx-input-group>
            <vs-input v-model="data.booking.no_kontrak" @click="modalBooking.active = true" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalBooking.active = true"/>
              </div>
            </template>
          </vx-input-group>
        </div>
        <div class="vx-col w-full sm:w-6/12">
          <label class="ml-1 text-xs">Kode *</label>
          <vs-input class="w-full" v-model="data.customer.kode" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full sm:w-6/12">
          <label class="ml-1 text-xs">Nama Pemilik *</label>
          <vs-input class="w-full" v-model="data.customer.nama"/>
        </div>
        <div class="vx-col w-full sm:w-6/12">
          <label class="ml-1 text-xs">Nama Pemesan</label>
          <vs-input class="w-full" v-model="data.booking.nama_pemesan"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">No. Identitas *</label>
          <vs-input class="w-full" v-model="data.customer.no_identitas"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Gender</label>
          <v-select :options="[{ nama: 'Laki-laki', gender: 'L'}, { gender: 'P', nama: 'Perempuan' }]" label="nama" :reduce="item => item.gender" :clearable="false" v-model="data.customer.gender"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tempat Lahir</label>
          <vs-input class="w-full" v-model="data.customer.tmp_lahir"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tanggal Lahir</label>
          <flat-pickr class="w-full" v-model="data.customer.tgl_lahir"></flat-pickr>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Telp</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-phone" v-model="data.customer.telp"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">HP *</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-smartphone" v-model="data.customer.hp"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">E-mail *</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-mail" v-model="data.customer.email"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Agama</label>
          <vs-input class="w-full" v-model="data.customer.agama"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kelurahan</label>
          <vs-input class="w-full" v-model="data.customer.kelurahan"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kota</label>
          <vs-input class="w-full" v-model="data.customer.kota"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kode Pos</label>
          <vs-input class="w-full" type="number" v-model="data.customer.pos"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tujuan Pembelian</label>
          <vs-input class="w-full" v-model="data.customer.tujuan_pembelian"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Domisili</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.domisili"/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Alamat *</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Sumber Dana</label>
          <vs-input class="w-full" v-model="data.customer.sumber_dana"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Nikah</label>
          <vs-input class="w-full" v-model="data.customer.status_nikah"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Nama Pasangan</label>
          <vs-input class="w-full" v-model="data.customer.nama_pasangan"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Tempat Tinggal</label>
          <vs-input class="w-full" v-model="data.customer.status_tmptgl"/>
        </div>
      </div>

      <vs-divider class="mt-20">Pekerjaan</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Profesi</label>
          <v-select :options="listProfesi" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.customer.id_profesi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Profesi</label>
          <vs-input class="w-full" v-model="data.customer.status_profesi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Jabatan</label>
          <vs-input class="w-full" v-model="data.customer.jabatan"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">NPWP</label>
          <vs-input class="w-full" type="number" v-model="data.customer.npwp"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat NPWP</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat_npwp"/>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat Surat</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat_surat"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">No. Rekening</label>
          <vs-input class="w-full" type="number" v-model="data.customer.no_rekening"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Nama Instansi</label>
          <vs-input class="w-full" v-model="data.customer.nama_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Telp. Instansi</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-phone" v-model="data.customer.telp_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Fax Instansi</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-printer" v-model="data.customer.fax_instansi"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat Instansi</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">CP Instansi</label>
          <vs-input class="w-full" v-model="data.customer.cp_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Jenis Usaha</label>
          <vs-input class="w-full" v-model="data.customer.jenis_usaha"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Tetap</label>
          <v-money class="w-full" v-model="data.customer.pengh_tetap"/>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Tambahan</label>
          <v-money class="w-full" v-model="data.customer.pengh_tambah"/>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Bulanan</label>
          <v-money class="w-full" v-model="data.customer.pengh_bulanan"/>
        </div>
      </div>

      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full">
          <vs-button :disabled="isLoading" @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>
            <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
            <span v-if="!isLoading">Lanjut</span>
          </vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Data Booking"
              :active="modalBooking.active"
              v-on:update:active="modalBooking.active = $event">
      <Booking :selectable="true"
               :externalFilter="{ status_booking: 'OPEN', status_approval_skup: 'DITERIMA' }"
               @selected="onSelectedModalBooking"/>
    </vs-popup>
  </div>
</template>

<script>
import ProfesiRepository from '@/repositories/master/profesi-repository'
import BookingRepository from '@/repositories/marketing/booking-repository'
import CustomerRepository from '@/repositories/master/customer-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Booking from '@/views/pages/marketing/booking/Booking'
import VMoney from '@/views/components/v-money/VMoney'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'TabCustomer',
  components: {
    VMoney,
    Booking,
    flatPickr,
    ValidationErrors,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProfesi()
  },
  computed: {
    errors () {
      return this.$store.state.marketing.kontrakAdd.errors.tabCustomer.messages
    }
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      isLoading: false,
      data: {
        booking: {},
        customer: {}
      },
      modalBooking: {
        active: false
      },
      listProfesi: []
    }
  },
  methods: {
    async onSelectedModalBooking (item) {
      this.modalBooking.active = false
      this.data = {
        booking: await this.getBookingById(item.id),
        customer: await this.getCustomerById(item.id_customer)
      }
    },

    getAllProfesi () {
      ProfesiRepository.getAllProfesi()
        .then(response => {
          this.listProfesi = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getBookingById (idBooking) {
      const params = { withAngsurans: 1 }
      return await BookingRepository.show(idBooking, params)
        .then(response => {
          return response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getCustomerById (idCustomer) {
      return await CustomerRepository.show(idCustomer)
        .then(response => {
          return response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function () {
      const payload = this.buildPayload()
      this.$store.commit('marketing/kontrakAdd/SET_TAB_CUSTOMER', payload)
    }, 500),

    next () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 1)
    },

    buildPayload () {
      return {
        id_booking_asal: this.data.booking.id,
        nama_pemesan: this.data.booking.nama_pemesan,
        booking: this.data.booking,
        customer: {
          id: this.data.customer.id,
          id_proyek: this.data.customer.id_proyek,
          id_profesi: this.data.customer.id_profesi,
          nama: this.data.customer.nama,
          no_identitas: this.data.customer.no_identitas,
          gender: this.data.customer.gender,
          tmp_lahir: this.data.customer.tmp_lahir,
          tgl_lahir: this.data.customer.tgl_lahir,
          domisili: this.data.customer.domisili,
          alamat: this.data.customer.alamat,
          kelurahan: this.data.customer.kelurahan,
          kota: this.data.customer.kota,
          pos: this.data.customer.pos,
          telp: this.data.customer.telp,
          hp: this.data.customer.hp,
          email: this.data.customer.email,
          agama: this.data.customer.agama,
          status_profesi: this.data.customer.status_profesi,
          npwp: this.data.customer.npwp,
          alamat_npwp: this.data.customer.alamat_npwp,
          alamat_surat: this.data.customer.alamat_surat,
          no_rekening: this.data.customer.no_rekening,
          jabatan: this.data.customer.jabatan,
          nama_instansi: this.data.customer.nama_instansi,
          telp_instansi: this.data.customer.telp_instansi,
          fax_instansi: this.data.customer.fax_instansi,
          alamat_instansi: this.data.customer.alamat_instansi,
          cp_instansi: this.data.customer.cp_instansi,
          jenis_usaha: this.data.customer.jenis_usaha,
          sumber_dana: this.data.customer.sumber_dana,
          tujuan_pembelian: this.data.customer.tujuan_pembelian,
          status_nikah: this.data.customer.status_nikah,
          nama_pasangan: this.data.customer.nama_pasangan,
          status_tmptgl: this.data.customer.status_tmptgl,
          pengh_tetap: this.data.customer.pengh_tetap,
          pengh_tambah: this.data.customer.pengh_tambah,
          pengh_bulanan: this.data.customer.pengh_bulanan
        }
      }
    }
  }
}
</script>
