<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-primary con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Silahkan upload file-file customer yang diperlukan.
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full px-0">
        <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
      </div>
    </div>
    <vs-table :data="rowsDokumen" stripe class="fix-table-input-background">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">Kode</vs-th>
        <vs-th class="whitespace-no-wrap">Nama</vs-th>
        <vs-th class="whitespace-no-wrap">Diterima</vs-th>
        <vs-th class="whitespace-no-wrap">File</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td>{{ index + 1 }}</vs-td>
          <vs-td><vs-input readonly v-model="item.kode_item_dokumen_customer"/></vs-td>
          <vs-td><vs-input readonly v-model="item.nama_item_dokumen_customer"/></vs-td>
          <vs-td class="align-middle justify-center text-center"><vs-checkbox class="w-8 m-0" v-model="item.isDiterima"/></vs-td>
          <vs-td><vs-input v-model="item.fileTemp" type="file" accept=".jpeg,.jpg,.png,.pdf" @change="item.file = $event.target.files[0]"/></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-6">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import ItemDokumenCustomerRepository from '@/repositories/master/item-dokumen-customer-repository'
import _ from 'lodash'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'

export default {
  name: 'TabDokumenCustomer',
  components: {
    ValidationErrors
  },
  data () {
    return {
      search: null,
      loading: false,
      id_profesi: null, // get from vuex
      id_cara_bayar: null, // get from vuex
      dokumen: []
    }
  },
  mounted () {
    if (this.id_profesi && this.id_cara_bayar) {
      this.getData()
    }
  },
  computed: {
    errors () {
      return this.$store.state.marketing.kontrakAdd.errors.tabDokumenCustomer.messages
    },
    storeTabCustomer () {
      return this.$store.state.marketing.kontrakAdd.tabCustomer
    },
    storeTabAngsuran () {
      return this.$store.state.marketing.kontrakAdd.tabAngsuran
    },
    rowsDokumen () {
      const search = this.search
      const items = this.dokumen
      if (search != null) {
        return _.filter(items, (item) => {
          return item.nama.toLowerCase().includes(search) || item.kode.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  watch: {
    storeTabCustomer (newVal, oldVal) {
      this.onChangeStoreTabCustomer(newVal, oldVal)
    },
    storeTabAngsuran (newVal, oldVal) {
      this.onChangeStoreTabAngsuran(newVal, oldVal)
    },
    dokumen: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  methods: {
    onChangeStoreTabCustomer (newVal, oldVal) {
      const newValIdProfesi = newVal.customer ? newVal.customer.id_profesi : null
      const oldValIdProfesi = oldVal.customer ? oldVal.customer.id_profesi : null
      if (!_.isEqual(newValIdProfesi, oldValIdProfesi)) {
        this.id_profesi = newValIdProfesi
        if (this.id_profesi && this.id_cara_bayar) {
          this.getData()
        }
      }
    },

    onChangeStoreTabAngsuran (newVal, oldVal) {
      const newValIdCaraBayar = newVal.id_cara_bayar
      const oldValIdCaraBayar = oldVal.id_cara_bayar
      if (!_.isEqual(newValIdCaraBayar, oldValIdCaraBayar)) {
        this.id_cara_bayar = newValIdCaraBayar
        if (this.id_profesi && this.id_cara_bayar) {
          this.getData()
        }
      }
    },

    getData () {
      this.loading = true
      ItemDokumenCustomerRepository.getDetailsByProfesiAndCaraBayar(this.id_profesi, this.id_cara_bayar)
        .then(response => {
          this.dokumen = _.map(response.data.data, function (item) {
            return _.extend({}, item, { isDiterima: false })
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    commitDataToStore: _.debounce(function () {
      const payload = this.buildPayload()
      this.$store.commit('marketing/kontrakAdd/SET_TAB_DOKUMEN_CUSTOMER', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 3)
    },

    next () {
      this.$store.commit('marketing/kontrakAdd/SET_ACTIVE_TAB', 5)
    },

    buildPayload () {
      return this.dokumen.map(it => {
        return {
          id_item_dokumen_customer: it.id_item_dokumen_customer,
          is_diterima: it.is_diterima ? 1 : 0,
          file: it.file
        }
      })
    }
  }
}
</script>
